<template src="./template.html"></template>

<script>
import {getRiskRatingClass} from "@/utils/utils";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "search-sites-float-window",
  data() {
    return {
      hiddenElement: true,
      query: '',
      currentOption: {icon: 'sort-alphabetical-ascending', text: 'Alphabetical order'},
      options: [
        {icon: 'sort-alphabetical-ascending', text: 'Alphabetical order'},
        {icon: 'exclamation', text: 'Risk rating'},
/*        {icon: 'arrow-up-thin-circle-outline', text: 'Top DCA'}*/
      ],
      isLoading: false,
     // selectedProject: this.getSelectedProject,
      searchText: null,
      showResults: false
    }
  },
  async mounted() {

    if (this.allProjects.length === 0) {
      this.isLoading = true
      await this.fetchUserProjects()
      this.isLoading = false
    }

    let queryData = this.searchQueryData;
    this.query = queryData.query
    this.currentOption = queryData.sort
    this.searchText = this.selectedProject ? this.selectedProject.name : null
  },
  methods: {
    ...mapActions({
      fetchUserProjects: 'getProjects',
      setSelectedProject: 'safetyModule/setSelectedProject',
      setSelectedDataDevice: 'safetyModule/setSelectedDataDevice',
      resetTableHeaderData: 'safetyModule/resetTableHeaderData',
      getSummaryData: 'safetyModule/getSummaryData',
      setSearchQuery: 'safetyModule/setSearchQuery'
    }),
    async onProjectSelected(project){
      console.log('On project selected: ', project)

      if(this.selectedProject && this.selectedProject._id === project._id){
        this.searchText = project.name
      }else {
        this.clearSearch()
        await this.setSelectedProject(project)
        await this.setSelectedDataDevice(null)
        await this.resetTableHeaderData()
        this.searchText = project.name
        this.showResults = false
        this.isLoading = true
        let start = Date.now()
        let response = await this.getSummaryData(project._id)
        if(response instanceof Error){
          console.log('Error show error: ', response)
          this.showToast('Can not show summary data: ' + response.message, 'is-danger')
        }
        let end = Date.now()

        console.log('Time is: ', (end - start)/1000)
        //emit on project selected
        this.$emit('onProjectSelected')
        this.isLoading = false
      }


    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
    clearSearch() {
      this.query = ''
      let data = {
        query: this.query,
        sort: this.currentOption
      }
      this.setSearchQuery(data)
    },
    getRatingClass(property) {
      return getRiskRatingClass(property)
    },
    onQueryChanged(query) {
      console.log('Query changed: ', query)
      //this.$emit('onQueryChanged', query)
      let data = {
        query: this.query,
        sort: this.currentOption
      }
      this.setSearchQuery(data)
    },
    async onSortChanged(value) {
      console.log('On sort changed: ', value)
      this.currentOption = value
      let data = {
        query: this.query,
        sort: this.currentOption
      }
      await this.setSearchQuery(data)
    },
    onViewClicked(site) {
      this.$emit('onViewClicked', site)
    },
    onFocus(){
      console.log('On search project focus')
      this.showResults = true
    },
    onBlur(){
      console.log('On search project blur:', this.searchText)
      console.log('On search project blur project:', this.selectedProject)
      if(this.selectedProject){
        if(this.selectedProject.name === this.searchText){
          this.showResults = false
        }else if(this.selectedProject.name !== this.searchText){
          setTimeout(()=>{
            this.searchText = this.selectedProject.name
            this.showResults = false
          }, 1000)
        }
      }
    },
    getEventClass(eventTypeClass){
      if(eventTypeClass){
        let split = eventTypeClass.split('\n')
        if(split.length > 0){
          return split[0]
        }
      }

      return null
    },
    getEventType(eventTypeClass){
      if(eventTypeClass){
        let split = eventTypeClass.split('\n')
        if(split.length > 1){
          return split[1]
        }
      }

      return null
    },
    onSearchTextProjectChanged(value){
      console.log('On search project text changed: ',value)
      this.searchText = value
    },
  },
  computed: {
    ...mapState({
      searchQueryData: state => state.safetyModule.searchQueryData
    }),
    ...mapGetters({
      getUserRole: 'getUserRole',
      allProjects:'getProjects',
      getSelectedProject: 'safetyModule/getSelectedProject',
      getFilteredSites: 'safetyModule/getFilteredSites'
    }),
    hideElement() {
      return this.hiddenElement ? '' : 'move_left';
    },
    allSites() {
      return this.getFilteredSites;
    },
    projectsList() {
      return this.allProjects
    },
    selectedProject(){
      return this.getSelectedProject
    },
    filteredProjects(){
      if(this.searchText && this.searchText.length > 0){
        return  this.allProjects.filter(project=> project.name.toLowerCase().includes(this.searchText.toLowerCase()))
      }else{
        return  this.allProjects
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>