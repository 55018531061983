import {lineString, lineIntersect} from "@turf/turf";
import {getSeverityRatingScore} from "@/utils/utils";

export default {
  /**
   * Update all sites after request
   * @param state
   * @param items
   */
  updateAllSites(state, items) {
    state.allSites = items
  },
  setSelectedDataDevice(state, dataDevice) {
    state.selectedDataDevice = dataDevice
  },
  setTableData(state, data) {
    let newData = data.sort((a, b) => {
      return new Date(b.eventAt) - new Date(a.eventAt)
    })

    let petValues = newData.map(data => data.eventData && data.eventData.petValue ? data.eventData.petValue : [])


    console.log('Pet values: ', petValues)

    let maxPet = Math.max(...petValues)
    let minPet = Math.min(...petValues)

    console.log('Pet value max: ', maxPet)
    console.log('Pet value min: ', minPet)

    newData.forEach(data => {
      let pathLeadUserPoints = []
      let pathFollowUserPoints = []

      if(data.eventData && data.eventData.value){
        petValues.push(data.eventData.petValue)
      }

      if(data.eventData && data.eventData.roadUsers && data.eventData.roadUsers.length > 1 && data.eventData.roadUsers[1].pathCentroid){
        if (data.eventData.roadUsers[1].pathCentroid.length > 0) {
          data.eventData.roadUsers[1].pathCentroid.forEach(pathCentroid=> {
            if (pathCentroid && pathCentroid.gps_centroid != null && pathCentroid.gps_centroid.length > 0) {
              //get first gps point
                pathFollowUserPoints.push(pathCentroid.gps_centroid)
            }
          })
        }
      }
      if(data.eventData && data.eventData.roadUsers && data.eventData.roadUsers.length > 0 && data.eventData.roadUsers[0].pathCentroid){
        if (data.eventData.roadUsers[0].pathCentroid.length > 0) {
          data.eventData.roadUsers[0].pathCentroid.forEach(pathCentroid => {
            if (pathCentroid && pathCentroid.gps_centroid != null && pathCentroid.gps_centroid.length > 0) {
              //get first gps point
                pathLeadUserPoints.push(pathCentroid.gps_centroid)
            }
          })
        }
      }

      let normalizedLeadPoints = pathLeadUserPoints.map(point => {
        return [point[1], point[0]]
      })
      let normalizedFollowPoints = pathFollowUserPoints.map(point => {
        return [point[1], point[0]]
      })

      if(normalizedFollowPoints.length > 0 && normalizedLeadPoints.length > 0){
        let lineLead = lineString(normalizedLeadPoints);
        let lineFollow = lineString(normalizedFollowPoints);
        let intersects = lineIntersect(lineLead, lineFollow);

        if(intersects.features.length > 0){
          intersects.features[0].properties['id'] = data._id
          intersects.features[0].properties['pet'] = data.eventData.petValue
          intersects.features[0].properties['dca'] = data.eventData.eventCode
          intersects.features[0].properties['severityRating'] = data.severityRating
          if(data.severityRating){
            //intersects.features[0].properties['heat_intensity'] = 100.0 - ((data.data.value/maxPet) * 100.0)
            intersects.features[0].properties['heat_intensity'] = getSeverityRatingScore(data.severityRating)
          }

          data['conflictPoint'] = intersects.features[0]

        }

        data.eventData.roadUsers[0]['line'] = lineLead
        data.eventData.roadUsers[1]['line'] = lineFollow
        data['min_pet'] = minPet
        data['max_pet'] = maxPet
      }

    })

    console.log('New data is: ', newData)

    state.tableData = newData
  },
  setSummary(state, data) {
    state.allSites.forEach(site => {
      let eventsForSite = data.filter(dt => dt.siteId === site._id)
      console.log('Events for site: ', eventsForSite)
    })
  },
  setQueryData(state, queryData) {
    state.searchQueryData = queryData
  },
  setSelectedProject(state, project) {
    state.selectedProject = project
  },
  setFileOperationsTableData(state, newData){
    state.fileOperationsTableData = newData
  },
  setTableHeaderData(state, newData){
    state.tableHeaderData = newData
  },
  setSingleUserTableHeaderData(state, newData){
    state.singleUserTableHeaderData = newData
  },
  resetTableHeaderData(state){
    state.tableHeaderData = {
      activeSorted: {
        field: '',
        order: ''
      },
      activeFilterField:'',
      filters:{risk: [], pet: null, dca: null, date: null, time:null, ttc:null, kineticEnergy:null, userTypes:null, type: [], direction: null}
    }
  },
  resetSingleUserTableHeaderData(state){
    state.singleUserTableHeaderData = {
      activeSorted: {
        field: '',
        order: ''
      },
      activeFilterField:'',
      filters:{date: null, time: null, userType: null, origin: null, destination: null, incidentType: null}
    }
  },
  clearAllData(state) {
    state.allSites = []
    state.allDevices = []
    state.selectedProjectsDevices = []
    state.selectedDataDevice = null
    state.selectedProject = null
    state.tableData = []
    state.fileOperationsTableData = []
    state.tableHeaderData = {
      activeSorted: {
        field: '',
        order: ''
      },
      activeFilterField:'',
      filters:{risk: [], pet: null, dca: null, date: null, time:null, ttc:null, kineticEnergy:null, userTypes:null, type: [],direction: null}
    }
  },
  setCurrentTablePage(state, pageNumber){
    state.currentTablePage = pageNumber
  }
};
