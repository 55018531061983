<template src="./event-details.html">

</template>

<script>
import {
  capitalizeFirstLetter,
  getDate,
  getEventClass,
  getRiskRatingClass,
  getTime,
  removeSlashAndCapitalize
} from "@/utils/utils";
import TrajectoryMap from "@/modules/safety-module/components/trajectory-map/trajectory-map.vue";
import {mapActions, mapState} from "vuex";
import IncidentsTable from "@/modules/safety-module/components/incidents-table/incidents-table.vue";
import SingleUserIncidentsTable
  from "@/modules/safety-module/components/single-user-incidents-table/single-user-incidents-table.vue";

export default {
  name: "event-details",
  components: {SingleUserIncidentsTable, IncidentsTable, TrajectoryMap},
  props: {
    eventId: {
      type: String,
      required: true,
      default: '',
    },
    projectId: {
      type: String,
      required: true,
      default: '',
    },
    dataDeviceId: {
      type: String,
      required: true,
      default: '',
    },
    pageNumber: {
      type: String,
      required: true,
      default: '1'
    }
  },
  data() {
    return {
      videoUrl: null,
      riskRating: '',
      dca: '',
      pet: '',
      kEnergy: '',
      ttc: '',
      width: 980,
      height: 564,
      ratio: 1.0,
      isLoading: false,
      files: [],
      description: null
    }
  },
  created() {
    this.$router.currentRoute.meta.topBarTitle = `Event ID: ${this.eventId}`
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEventHandler)
  },
  async mounted() {
    console.log('data device id: ', this.dataDeviceId)
    console.log('Project id: ', this.projectId)
    this.ratio = this.width / this.height
    console.log('Event id: ', this.eventId)
    window.addEventListener("resize", this.resizeEventHandler);
    this.calculateSizes()

    this.isLoading = true
    //Restore state after reload page
    await this.restoreState({projectId: this.projectId, dataDeviceId: this.dataDeviceId, pageNumber: this.pageNumber})

    let event = this.stateTableData.find(td => td._id === this.eventId)

    console.log('Event: ', event)
    this.isLoading = false
    if (event != null) {
      this.isLoading = true

      if (getEventClass(event.eventData.eventClass) === 'PET') {
        this.pet = event.eventData.petValue ? event.eventData.petValue >= 0.0 ? event.eventData.petValue + ' s' : 'N/A' : 'N/A'
      } else {
        if (event.eventData.eventClass) {
          console.log('Event type: ', event.eventData.eventClass)
          let renderClass = '-'
          renderClass = event.eventData.eventClass.replace(/_/g, ' ')
          renderClass = removeSlashAndCapitalize(renderClass)

          let renderType = event.eventData.eventType ? event.eventData.eventType.replace(/_/g, ' ') : null
          console.log('Render type', renderType)
          if (renderType === null || renderType === undefined || renderType === 'undefined') {
            renderType = ''
          } else {
            renderType = '/' + removeSlashAndCapitalize(renderType)
          }


          this.description = `${renderClass}${renderType}`
        }
      }


      this.dca = event.eventData.eventCode
      this.riskRating = event.severityRating !== null && event.severityRating !== undefined ? capitalizeFirstLetter(event.severityRating) : 'Undefined'
      this.kEnergy = 'xxx'
      this.ttc = event.eventData.ttcValue

      let pathLeadUserPoints = []
      let pathFollowUserPoints = []


      if (event.eventData && event.eventData.roadUsers && event.eventData.roadUsers.length > 1 && event.eventData.roadUsers[1].pathCentroid) {
        if (event.eventData.roadUsers[1].pathCentroid.length > 0) {
          event.eventData.roadUsers[1].pathCentroid.forEach(pathCentroid => {
            if (pathCentroid && pathCentroid.gps_centroid != null && pathCentroid.gps_centroid.length > 0) {
              //get first gps point
              pathFollowUserPoints.push(pathCentroid.gps_centroid)
            }
          })
        }
      }
      if (event.eventData && event.eventData.roadUsers && event.eventData.roadUsers.length > 0 && event.eventData.roadUsers[0].pathCentroid) {
        if (event.eventData.roadUsers[0].pathCentroid.length > 0) {
          event.eventData.roadUsers[0].pathCentroid.forEach(pathCentroid => {
            if (pathCentroid && pathCentroid.gps_centroid != null && pathCentroid.gps_centroid.length > 0) {
              //get first gps point
              pathLeadUserPoints.push(pathCentroid.gps_centroid)
            }
          })
        }
      }

      let response = await this.startVideoDownload(event.videoSnippetMetadataId)
      this.videoUrl = response.url

      this.files = [
        {
          url: this.videoUrl,
          name: 'name_1.mp4'
        },
        {
          url: this.videoUrl,
          name: 'name_2.mp4'
        },
        {
          url: this.videoUrl,
          name: 'name_3.mp4'
        }
      ]

      if (this.$refs.trajectoryMap) {
        setTimeout(() => {
          this.$refs.trajectoryMap.loadPath(pathLeadUserPoints, pathFollowUserPoints)
        }, 1000)
      }

      this.isLoading = false
      console.log('Video url: ', this.videoUrl)
    }

  },
  methods: {
    ...mapActions({
      restoreState: 'safetyModule/restoreState',
      startVideoDownload: 'safetyModule/startVideoDownload',
    }),
    calculateSizes() {
      if (this.$refs.videoRef) {
        this.width = Math.round(this.$refs.videoRef.clientWidth)
        this.height = Math.round(this.width / this.ratio)
        if (this.$refs.trajectoryMap) {
          this.$refs.trajectoryMap.setMapHeight(this.height - 16)
        }
      }
    },
    resizeEventHandler(event) {
      console.log('Resize event handler:', event)
      this.calculateSizes()
    },
    getDate(date) {
      return getDate(date)
    },
    getTime(date) {
      return getTime(date)
    },
    getRiskColor(risk) {
      return getRiskRatingClass(risk)
    },
    loadIncidentsTable() {
      if (this.selectedProject && this.selectedProject.types && this.selectedProject.types.length === 0) {
        return true
      } else {
        return false
      }
    },
    loadSingleUserIncidentTable() {
      if (this.selectedProject && this.selectedProject.types && this.selectedProject.types.length > 0 && this.selectedProject.types.includes('DTP_SURVEY')) {
        return true
      } else {
        return false
      }
    }
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
      stateTableData: state => state.safetyModule.tableData,
    }),
    tableData() {

      let tableData = []

      if (this.stateTableData != null) {
        let event = this.stateTableData.find(td => td._id === this.eventId)
        if (event != null) {


          if (getEventClass(event.eventData.eventClass) === 'PET') {

            if (event.eventData.roadUsers.length > 0) {
              let userClass = event.eventData.roadUsers[0] ? event.eventData.roadUsers[0].roadUserClass : 'Undefined'
              let userType = event.eventData.roadUsers[0] ? event.eventData.roadUsers[0].roadUserType : 'Undefined'
              tableData.push({
                roadUser: 'Lead',
                date: this.getDate(event.eventData.roadUsers[0].conflictZoneExitTime),
                time: this.getTime(event.eventData.roadUsers[0].conflictZoneExitTime),
                userClass: `${removeSlashAndCapitalize(userClass)}`,
                userType: `${removeSlashAndCapitalize(userType)}`,
                speed: event.eventData.roadUsers[0] && event.eventData.roadUsers[0].roadUserSpeed ? event.eventData.roadUsers[0].roadUserSpeed.toFixed(2) + ' km/h' : '-km/h',
                origin: event.eventData.roadUsers[0] && event.eventData.roadUsers[0].origin ? event.eventData.roadUsers[0].origin : '-',
                destination: event.eventData.roadUsers[0] && event.eventData.roadUsers[0].destination ? event.eventData.roadUsers[0].destination : '-',
                movement: event.eventData.roadUsers[0] && event.eventData.roadUsers[0].movement ? event.eventData.roadUsers[0].movement : '-'
              })
            }

            if (event.eventData.roadUsers.length > 1) {
              let userClass = event.eventData.roadUsers[1] ? event.eventData.roadUsers[1].roadUserClass : 'Undefined'
              let userType = event.eventData.roadUsers[1] ? event.eventData.roadUsers[1].roadUserType : 'Undefined'
              tableData.push({
                roadUser: 'Follow',
                date: this.getDate(event.eventData.roadUsers[1].conflictZoneEnterTime),
                time: this.getTime(event.eventData.roadUsers[1].conflictZoneEnterTime),
                userClass: `${removeSlashAndCapitalize(userClass)}`,
                userType: `${removeSlashAndCapitalize(userType)}`,
                speed: event.eventData.roadUsers[1] && event.eventData.roadUsers[1].roadUserSpeed ? event.eventData.roadUsers[1].roadUserSpeed.toFixed(2) + ' km/h' : '-km/h',
                origin: event.eventData.roadUsers[1] && event.eventData.roadUsers[1].origin ? event.eventData.roadUsers[1].origin : '-',
                destination: event.eventData.roadUsers[1] && event.eventData.roadUsers[1].destination ? event.eventData.roadUsers[1].destination : '-',
                movement: event.eventData.roadUsers[1] && event.eventData.roadUsers[1].movement ? event.eventData.roadUsers[1].movement : '-'
              })
            }
          } else {

            if (event.eventData.roadUsers.length > 0) {
              let userClass = event.eventData.roadUsers[0] ? event.eventData.roadUsers[0].roadUserClass : 'Undefined'
              let userType = event.eventData.roadUsers[0] ? event.eventData.roadUsers[0].roadUserType : 'Undefined'
              tableData.push({
                roadUser: 'Lead',
                date: this.getDate(event.eventData.roadUsers[0].conflictZoneExitTime),
                time: this.getTime(event.eventData.roadUsers[0].conflictZoneExitTime),
                userClass: `${removeSlashAndCapitalize(userClass)}`,
                userType: `${removeSlashAndCapitalize(userType)}`,
                speed: event.eventData.roadUsers[0] && event.eventData.roadUsers[0].roadUserSpeed ? event.eventData.roadUsers[0].roadUserSpeed.toFixed(2) + ' km/h' : '-km/h',
                origin: event.eventData.roadUsers[0] && event.eventData.roadUsers[0].origin ? event.eventData.roadUsers[0].origin : '-',
                destination: event.eventData.roadUsers[0] && event.eventData.roadUsers[0].destination ? event.eventData.roadUsers[0].destination : '-',
                movement: event.eventData.roadUsers[0] && event.eventData.roadUsers[0].movement ? event.eventData.roadUsers[0].movement : '-'
              })
            }

            if (event.eventData.roadUsers.length > 1 && event.eventData.roadUsers[0].userId !== event.eventData.roadUsers[1].userId.userId) {
              //Not single road user
              let userClass = event.eventData.roadUsers[1] ? event.eventData.roadUsers[1].roadUserClass : 'Undefined'
              let userType = event.eventData.roadUsers[1] ? event.eventData.roadUsers[1].roadUserType : 'Undefined'
              tableData.push({
                roadUser: 'Follow',
                date: this.getDate(event.eventData.roadUsers[1].conflictZoneExitTime),
                time: this.getTime(event.eventData.roadUsers[1].conflictZoneExitTime),
                userClass: `${removeSlashAndCapitalize(userClass)}`,
                userType: `${removeSlashAndCapitalize(userType)}`,
                speed: event.eventData.roadUsers[1] && event.eventData.roadUsers[1].roadUserSpeed ? event.eventData.roadUsers[1].roadUserSpeed.toFixed(2) + ' km/h' : '-km/h',
                origin: event.eventData.roadUsers[1] && event.eventData.roadUsers[1].origin ? event.eventData.roadUsers[1].origin : '-',
                destination: event.eventData.roadUsers[1] && event.eventData.roadUsers[1].destination ? event.eventData.roadUsers[1].destination : '-',
                movement: event.eventData.roadUsers[1] && event.eventData.roadUsers[1].movement ? event.eventData.roadUsers[1].movement : '-'
              })
            }
          }


        }
      }

      return tableData
    }
  },
}
</script>

<style scoped>

</style>